import { onFind } from "@elements/init-modules-in-scope";
import { findIn, on } from '@elements/dom-utils';

export function init() {
    onFind('.js-tabbing-area', function (area) {
        const leftArrow = findIn('.js-left-arrow', area);
        const rightArrow = findIn('.js-right-arrow', area);
        const scrollElem = findIn('.js-scroll-on-desktop', area);
        const scrollAmount = 200;

        on('click', function () {
            scrollElem.scrollBy({
                left: -scrollAmount,
                behavior: 'smooth'
            });
        }, leftArrow);

        on('click', function () {
            scrollElem.scrollBy({
                left: scrollAmount,
                behavior: 'smooth'
            });
        }, rightArrow);
    });
}